"use strict";

$(document).ready(function ($) {
  var page = 1;
  var loadMoreButton = $('#load-more');
  var resetFiltersButton = $('#reset-filters');
  var applyFiltersButton = $('#apply-filters');
  function applyFilters() {
    var loadMore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    var selectedCategories = [];
    $('input[name="category[]"]:checked').each(function () {
      selectedCategories.push($(this).val());
    });
    var sortOption = $('input[name="sort"]:checked').val();
    var searchQuery = $('#search').val(); // Получаем значение поля поиска

    var data = {
      category: selectedCategories,
      sort: sortOption,
      search: searchQuery,
      // Добавляем поисковый запрос
      paged: page
    };
    $.ajax({
      url: ajaxUrl + '?action=filter_posts',
      type: 'GET',
      data: data,
      dataType: 'json',
      success: function success(response) {
        if (loadMore) {
          $('#posts-container').append(response.posts);
        } else {
          $('#posts-container').html(response.posts);
        }
        if (response.no_more_posts) {
          loadMoreButton.hide(); // Скрываем кнопку, если больше нет постов
        } else {
          loadMoreButton.show();
        }
        sliderSet();
      },
      error: function error(xhr, status, _error) {
        console.log('Error: ' + _error);
      }
    });

    // Управляем отображением кнопки сброса фильтров
    resetFiltersButton.toggle(selectedCategories.length > 1);
    if ($(window).width() <= 768) {
      applyFiltersButton.toggle(selectedCategories.length > 0);
    }
  }

  // Управление фильтрами при изменении значений
  function handleFilters() {
    // Обновляем фильтры
    page = 1; // Сбрасываем номер страницы при любом изменении фильтра
    applyFilters(); // Применяем фильтры
  }
  function sliderSet() {
    $('.slider-banner').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 5000
    });
  }
  sliderSet();

  // Применение фильтров при изменении чекбоксов категорий или полей поиска
  $('input[name="category[]"]').on('change keyup', function () {
    var selectedCategories = $('input[name="category[]"]:checked').length;
    if (selectedCategories > 0) {
      // Снимаем все выбранные опции в other-options
      $('input[name="sort"]').prop('checked', false);
    }
    handleFilters(); // Обрабатываем фильтры
  });
  $('input[name="sort"]').on('change keyup', function () {
    var selectedSortOption = $('input[name="sort"]:checked').length;
    if (selectedSortOption > 0) {
      // Снимаем все выбранные опции в category-filter
      $('input[name="category[]"]').prop('checked', false);
    }
    handleFilters(); // Обрабатываем фильтры
  });
  $('#search').on('change keyup', function () {
    var searchValue = $(this).val().trim();

    // Если поле поиска пустое, вызываем handleFilters
    if (searchValue === '') {
      handleFilters();
    }
  });

  // Загрузка дополнительных постов при клике на "Показать еще"
  // loadMoreButton.on('click', function() {
  //     page++;  // Увеличиваем номер страницы
  //     applyFilters(true);  // Применяем фильтры и загружаем больше постов
  // });

  $('#load-more').on('click', function () {
    loadMoreButton.prop('disabled', true); // Блокируем кнопку во время загрузки
    page++;
    $.ajax({
      url: ajaxUrl,
      type: 'GET',
      data: {
        action: 'load_more_posts',
        paged: page
        // Добавьте другие параметры, если нужно
      },
      success: function success(response) {
        if (response.posts) {
          $('.posts-container').append(response.posts); // Добавляем новые посты
          page++; // Увеличиваем номер страницы только при успешной загрузке
        }
        if (response.no_more_posts) {
          loadMoreButton.hide(); // Скрываем кнопку, если больше нет постов
        } else {
          loadMoreButton.show();
        }
      },
      error: function error(xhr, status, _error2) {
        console.error('AJAX Error: ', xhr.responseText); // Обработка ошибок
      },
      complete: function complete() {
        loadMoreButton.prop('disabled', false); // Разблокируем кнопку после завершения запроса
      }
    });
  });

  // Сбрасываем фильтры при нажатии на кнопку "Сбросить фильтры"
  resetFiltersButton.on('click', function (e) {
    e.preventDefault(); // Предотвращаем стандартное действие кнопки
    $('#filter-form')[0].reset(); // Сбрасываем форму
    page = 1; // Сбрасываем номер страницы
    handleFilters(); // Обрабатываем фильтры после сброса
  });
  applyFiltersButton.on('click', function (e) {
    e.preventDefault();
    page = 1;
    handleFilters();
    $('.filter-left').removeClass('open');
  });
  $('#search-btn').on('click', function () {
    handleFilters(); // Обрабатываем фильтры при нажатии кнопки поиска
  });
  $('#search').on('keypress', function (e) {
    if (e.which === 13) {
      handleFilters();
      e.preventDefault();
    }
  });
});

// blog

$(document).ready(function () {
  var $categoryFilterLabel = $('label[for="category-filter"]');
  var $categoryFilterList = $('#category-filter');
  var $filterForm = $('#filter-form');
  var $closeBtn = $('.close-btn');
  $categoryFilterLabel.on('click', function () {
    if (!$categoryFilterList.hasClass('show')) {
      $categoryFilterLabel.addClass('active');
      if ($(window).width() <= 768) {
        $categoryFilterList.css('display', 'grid');
      } else {
        $categoryFilterList.css('display', 'flex');
      }
      setTimeout(function () {
        $categoryFilterList.addClass('show'); // Добавляем класс для появления
      }, 10); // Небольшая задержка для работы анимации
      $filterForm.addClass('open');
    } else {
      $categoryFilterList.removeClass('show'); // Убираем класс для исчезновения
      setTimeout(function () {
        $categoryFilterList.css('display', 'none'); // Скрываем блок после завершения анимации
      }, 300); // Задержка равна времени transition в CSS
      $categoryFilterLabel.removeClass('active');
      $filterForm.removeClass('open');
    }
  });
  $('.wrapper-search #search-btn').on('click', function () {
    var $input = $('.wrapper-search input[type="text"]');
    if ($input.hasClass('active')) {
      if ($input.val().trim() === "") {
        $input.removeClass('active');
      }
    } else {
      $input.addClass('active').focus(); // Автоматически фокусируемся на поле ввода
    }
  });
  $('.wrapper-search input[type="text"]').on('blur', function () {
    if ($(this).val().trim() === "") {
      $(this).removeClass('active');
    }
  });
  if ($(window).width() <= 768) {
    $('.filter-top').on('click', function () {
      $('.filter-left').toggleClass('open');
    });
  }
  $closeBtn.on('click', function () {
    $('.filter-left').removeClass('open');
  });
  document.querySelector('#apply-filters').addEventListener('click', function (event) {
    $('.filter-left').removeClass('open');
  });
  if ($(window).width() <= 768) {
    $('input[name="sort"]').on('change keyup', function () {
      var filterLeft = $('.filter-left');
      setTimeout(function () {
        filterLeft.removeClass('open');
      }, 300);
    });
  }
});